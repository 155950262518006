<template>
    <questions-results
        :questions-uuid="quuid"
        shared-type="group"
        :shared-uuid="uuid"
        :author-uuid="teacherUuid"
        :members="members"
    />
</template>

<script>
import { getStudentsCollection } from '@apps/school/api/students'
import QuestionsResults from '@apps/questions/components/QuestionsResults'
import { memberFullName } from '@/components/Member/utils'

export default {
    name: 'GroupQuestionsResults',
    components: { QuestionsResults },
    props: {
        uuid: {
            type: String,
            required: true
        },
        quuid: {
            type: String,
            required: true
        }
    },
    computed: {
        teacherUuid() {
            return this.$auth.user.uuid
        }
    },
    data() {
        return {
            members: []
        }
    },
    mounted() {
        this.fetchStudents()
    },
    methods: {
        async fetchStudents() {
            this.$lprogress.begin()

            try {
                const responseMembers = await getStudentsCollection({
                    group: this.uuid,
                    with: 'auth'
                })

                responseMembers.data.data.forEach(o => {
                    this.members.push({
                        name: memberFullName(o),
                        uuid: o.auth.uuid
                    })
                })
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
